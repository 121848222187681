import { createChatBotMessage} from "react-chatbot-kit";
import React from "react";
import './header.css'

const config = {
    initialMessages: [createChatBotMessage("Hi there, I'm VoxIQ. " +
        "I'm here to help you analyse your survey feedback. " +
        "Type a question in below.")],
    customComponents: {
        header: () => (
            <div className="react-chatbot-kit-chat-header">
                <img
                    src={require("./images/voxxifyIconCircle.png")}
                    alt="Voxxify Icon"
                    className="img-style"
                />
                VoxIQ
            </div>
        )
    }
}

export default config