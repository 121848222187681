class MessageParser {
    constructor(actionProvider, state) {
        this.actionProvider = actionProvider;
        this.state = state;
    }

    parse(message) {
        const lowerCaseMessage = message.toLowerCase()

        if (lowerCaseMessage.includes("hello")) {
            this.actionProvider.greet()
        }
        else if (lowerCaseMessage.includes("bye")) {
            this.actionProvider.leave()
        }
        else {
            this.actionProvider.handleSomeAction(message)
        }
    }
}

export default MessageParser;