import Fetcher from "./fetcher";
class ActionProvider {
    constructor(
        createChatBotMessage,
        setStateFunc,
        createClientMessage,
        stateRef,
        createCustomMessage,
        ...rest
    ) {
        this.createChatBotMessage = createChatBotMessage;
        this.setState = setStateFunc;
        this.createClientMessage = createClientMessage;
        this.stateRef = stateRef;
        this.createCustomMessage = createCustomMessage;
    }

    handleSomeAction = async (message) => {
        const data = new FormData();
        data.append('question', message)

        const dataMessage = await fetch('https://api.voxxchat.dev.voxxify.com/question/',{
            method: 'post',
            body: data
        })
            .then(response => response.json())
            /*.then(json => console.log(json))*/

        const botMessage = this.createChatBotMessage(dataMessage.answer);
        this.updateChatbotState(botMessage);
    }


    greet() {
        const greetingMessage = this.createChatBotMessage("Hi there!")
        this.updateChatbotState(greetingMessage)
    }
    leave() {
        const leavingMessage = this.createChatBotMessage("Farewell.")
        this.updateChatbotState(leavingMessage)
    }

    updateChatbotState(message) {

// NOTE: This function is set in the constructor, and is passed in      // from the top level Chatbot component. The setState function here     // actually manipulates the top level state of the Chatbot, so it's     // important that we make sure that we preserve the previous state.


        this.setState(prevState => ({
            ...prevState, messages: [...prevState.messages, message]
        }))
    }
}

export default ActionProvider;